import React, { useEffect } from 'react'
import { Modal, Button, Input, Textarea, Box, Text, Fieldset, Select } from '@mantine/core';
import { useUserCustomer } from '../../store/useUserCustomer';
import { useCustomer } from '../../store/useCustomer';
import { post_unlink_customer } from '../../utils/apis/customerAccount';

const ModalLink = ({ onClose, isOpen, item }) =>
{
  const { openModal, setCloseModal, postLink, user, postUnLink } = useUserCustomer()
  const { customers, getCustomersForLink } = useCustomer()
  const [ idCustomer, setIdCustomer ] = React.useState('')
  useEffect(() =>
  {
    if (openModal) {
      getCustomersForLink()
      console.log(user)
    }
  }, [ openModal ])

  const close = () =>
  {
    setCloseModal()
  }

  const handleSubmit = (e) =>
  {
    e.preventDefault()
    if (idCustomer !== '') {
      postLink(user._id, { id_customer: idCustomer })
    }
    if (user?.id_customer) {
      postUnLink(user._id, { id_customer: user?.id_customer._id})
    }
  }

  const optionsFilter = ({ options, search }) =>
  {
    const splittedSearch = search.toLowerCase().trim().split(' ');
    return (options).filter((option) =>
    {
      const words = option.label.toLowerCase().trim().split(' ');
      return splittedSearch.every((searchWord) => words.some((word) => word.includes(searchWord)));
    });
  };
  return (
    <Modal size="md" opened={ openModal } onClose={ () => setCloseModal() } title={ !item?._id ? 'Vincular Cliente' : 'Desvincular Cliente' } centered>
      { !user?.id_customer ? <Select
        label="Selecciona la cuenta a vincular"
        placeholder="Cuenta"
        onChange={ (value) => setIdCustomer(value) }
        data={ customers.map((customer) => ({
          value: customer._id,
          label: customer.num_account
        })) }
        filter={ optionsFilter }
        searchable
      /> :
        <Text>Deseas desvincular a { user.full_name } de la cuenta { user?.id_customer?.num_account }</Text>
      }
      <Box className='flex flex-row justify-end gap-2 my-2'>
        <Button variant='outline' color='gray' onClick={ close }>Cerrar</Button>
        <Button color='yellow' autoContrast onClick={ handleSubmit }>Guardar</Button>
      </Box>
    </Modal>
  )
}

export default ModalLink