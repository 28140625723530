import React, { useContext } from "react";
import { currencyFormat } from "simple-currency-format";
import dayjs from "dayjs";
import { Card, Text, Grid, Box, Flex, Image, ActionIcon, Tooltip } from "@mantine/core";

import Banregio from "../../assets/banks/banregio.webp";
import Azteca from "../../assets/banks/azteca.webp";
import Bbva from "../../assets/banks/bbva2.png";
import Hsbc from "../../assets/banks/hsbc.png";
import MercadoPago from "../../assets/banks/mercado-pago.webp";
import Santander from "../../assets/banks/santander-logo-1.webp";
import Efectivo from "../../assets/banks/efectivo.png";
import Spin from "../../assets/banks/spin.png";
import { HiTrash } from "react-icons/hi";
import AuthContext from "../../contexts/Auth/authContext";
const bankImages = {
  "Mercado Pago": MercadoPago,
  BBVA: Bbva,
  Santander: Santander,
  Hsbc: Hsbc,
  Banregio: Banregio,
  Azteca: Azteca,
  Efectivo: Efectivo,
  Spin: Spin,
};

const bankName = {
  "Mercado Pago": MercadoPago,
  BBVA: Bbva,
  Santander: Santander,
  Hsbc: Hsbc,
  Banregio: Banregio,
  Azteca: Azteca,
  Efectivo: Efectivo,
  Spin: Spin,
};

const Record = ({ item, rollBackRenovation }) =>
{
  const { username } = useContext(AuthContext);
  const renderBankImage = (bankName) => (
    <Box className="w-12 h-12 rounded-full border-2 flex flex-col items-center justify-center p-1 bg-gray-100">
      <Image
        src={ bankImages[ bankName ] }
        alt={ bankName }
        title={ bankName }
        className="w-auto h-auto"
      />

    </Box>
  );

  const renderGridContent = () => (
    <Flex direction="row" justify='space-between' py="sm">
      <Text size="lg" fw="bold" >Monto</Text>
      <Text size="lg" fw="bold" align="right" weight={ 600 }>
        { currencyFormat(item.amount, "es-MX", "MXN", 2) } mxn
      </Text>
    </Flex>
  );

  const renderRollBackAction = () => (
    (username === 'cobas' || username === 'jvaldes') ? (
      <Box className="flex items-center">
        <Tooltip label="Revertir renovación">
          <ActionIcon size="lg" variant="subtle" color="red" onClick={ () => rollBackRenovation(item) } padding="sm">
            <HiTrash size="20" />
          </ActionIcon>
        </Tooltip>
      </Box>
    ) : null
  )

  return (
    <Card shadow="sm" padding="none" radius="md" withBorder>
      <Card.Section pb="md" py="sm" px="md" withBorder>
        <Box className="flex items-center justify-end">
          { renderRollBackAction() }
        </Box>
        <Flex justify="space-between" align="center">
          <Box className="flex flex-row gap-2 items-center">
            { renderBankImage(item.type_bank_name) }
            <Box>
              <Text size="lg" fw="bold">
                { item.type_bank_name }
              </Text>
              <Text size="sm" c='gray'>{ item.type_contract }</Text>
            </Box>
          </Box>

          <Box className="flex flex-col items-end">
            <Text size="lg" fw="bold">{ dayjs(item?.created_date).format("DD-MM") }</Text>
            <Text size="md" c="gray">{ item.num_account }</Text>
          </Box>

        </Flex>
      </Card.Section>
      <Card.Section py="md" px="md">
        { renderGridContent() }
      </Card.Section>
    </Card>
  );
};

export default Record;
