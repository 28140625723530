import React, { useEffect, useState } from 'react'
import
{
  Card,
  Title, Box, ActionIcon, Skeleton, Grid,
  Flex, Input,
  Alert
} from '@mantine/core'
import { HiOutlineSearch } from 'react-icons/hi'
import { useUserCustomer } from '../store/useUserCustomer'
import UsersCustomersTable from '../components/UsersCustomers/UsersCustomerTable'
import ModalLink from '../components/UsersCustomers/ModalLink'
const UsersCustomers = () =>
{
  const { users, getUsers, loading } = useUserCustomer()

  const [ localLoading, setLocalLoading ] = useState(true)
  const [ searchText, setSearchText ] = useState('')
  const [ items, setItems ] = useState(null);
  const [ item, setItem ] = useState(null)

  useEffect(() =>
  {
    setLocalLoading(true)
    getUsers()
    setLocalLoading(false)
    setItems(users)
  }, [ loading ])


  const searchBd = (texto) =>
  {
    const newSearchText = texto;
    setSearchText(newSearchText);

    if (!users) return;

    if (!newSearchText.trim()) {
      setItems(users);
      return;
    }

    const filters = newSearchText.trim().split(' ');
    let resultFilter = users;

    for (let fl of filters) {
      if (!fl.trim()) continue;

      resultFilter = resultFilter.filter(item =>
      {
        const itemData = `${ (item.full_name || '').toUpperCase() } ${ (item.email || '').toUpperCase() }`;
        const textData = fl.toUpperCase();
        return itemData.includes(textData);
      });
    }

    setItems(resultFilter);
  }
  return (
    <Box className='w-full gap-1 pt-2 px-2'>
      <Alert color='yellow'>
        <p>
          <strong>Nota: </strong>
          Seccion en construcción.
        </p>
      </Alert>
      <Flex direction={ { base: 'column', lg: "row" } } justify="space-between" pb="lg" >
        <Title order={ 1 } my="sm">Acceso clientes </Title>
        <Flex direction={ { base: 'column', lg: "row" } } justify="flex-end" align="center" gap="md">
          <Flex align="center" gap="xs" w={ { base: '100%', lg: 300 } }>
            <Input size="lg" w={ { base: '100%', lg: 300 } } variant='filled' placeholder='Buscar cliente' value={ searchText } onChange={ (e) => searchBd(e.target.value) } />
            <ActionIcon size="xl" color='yellow' variant='light' radius='md' autoContrast onClick={ () => searchBd(searchText) } ><HiOutlineSearch /></ActionIcon>
          </Flex>
        </Flex>


      </Flex>

      { !localLoading ?
        <UsersCustomersTable items={ items } /> :
        <Card shadow="sm" p="xl" radius="xl" withBorder mx="md" w="100%">
          <Grid w="100%">
            <Skeleton height={ 80 } radius="md" my="xs" />
            <Skeleton height={ 80 } radius="md" my="xs" />
            <Skeleton height={ 80 } radius="md" my="xs" />
            <Skeleton height={ 80 } radius="md" my="xs" />
            <Skeleton height={ 80 } radius="md" my="xs" />
            <Skeleton height={ 80 } radius="md" my="xs" />
            <Skeleton height={ 80 } radius="md" my="xs" />

          </Grid>
        </Card>
      }

      <ModalLink />
    </Box>
  )
}

export default UsersCustomers