import React from "react";
import {
  Card,
  Badge,
  Text,
  Box,
  ActionIcon,
  Flex,
  Menu,
  SimpleGrid,
} from "@mantine/core";
import {
  HiOutlineTrash,
  HiOutlinePencilAlt,
  HiDotsVertical,
  HiReceiptTax,
  HiOutlineClipboard,
  HiCreditCard,
  HiOutlineCalendar,
  HiOutlineClock,
} from "react-icons/hi";
import {
  MdOutlineMail,
  MdOutlinePhone,
  MdOutlinePhoneIphone,
  MdMonetizationOn,
} from "react-icons/md";
import dayjs from "dayjs";
import "dayjs/locale/es";

const CustomerCard = ({
  item,
  onOpen,
  openConfirm,
  openRecordModal,
  _openManualRenovation,
}) => {
  return (
    <Card withBorder shadow="sm" radius="md" padding="md">
      <Card.Section m="sm" withBorder>
        <Box className="flex flex-row justify-between items-start gap-2">
          <Box className="w-[80%]">
            <Text size="lg" fw="bold" className="truncate" truncate>
              {item?.full_name ? item.full_name : "N/A"}
            </Text>
            <Text size="md" fw="normal" c="gray" className="font-mono">
              {item?.num_account}
            </Text>
          </Box>
          <Flex justify="flex-end" align="center" gap="xs" className="w-4">
            <Menu shadow="md" width={200}>
              <Menu.Target>
                <ActionIcon
                  aria-label="Ver acciones"
                  variant="light"
                  color="gray.6"
                  size="lg"
                >
                  <HiDotsVertical />
                </ActionIcon>
              </Menu.Target>
              <Menu.Dropdown>
                <Menu.Item
                  onClick={() => onOpen(item, true)}
                  leftSection={<HiOutlinePencilAlt />}
                  aria-label="Editar cliente"
                >
                  Editar cliente
                </Menu.Item>

                <Menu.Item
                  onClick={() => openRecordModal(item.num_account)}
                  leftSection={<HiReceiptTax />}
                  aria-label="Ver historial de pago "
                >
                  Ver historial de pago
                </Menu.Item>
                <Menu.Divider />
                <Menu.Item
                  color="blue"
                  onClick={() => _openManualRenovation(item)}
                  leftSection={<MdMonetizationOn />}
                  aria-label="Nueva renovación"
                >
                  Nueva renovación
                </Menu.Item>

                <Menu.Divider />
                <Menu.Item
                  color="red"
                  onClick={() => openConfirm(item)}
                  aria-label="Borrar cliente"
                  leftSection={<HiOutlineTrash />}
                >
                  Borrar cliente
                </Menu.Item>
              </Menu.Dropdown>
            </Menu>
          </Flex>
        </Box>
        <SimpleGrid cols={2} my="md">
          <Box>
            <Text size="sm" fw="bold" c="gray.6" className="font-sans">
              Fecha de corte
            </Text>
            <Text size="md" fw="bolder" className="flex flex-row items-center">
              <HiOutlineCalendar className="mr-1 text-yellow-500" />{" "}
              {dayjs(item?.date_billing)
                .locale("es")
                .format("DD/MMM/YYYY")
                .toUpperCase()}
            </Text>
          </Box>
          <Box>
            <Text size="sm" fw="bold" c="gray.6" className="font-sans">
              Fecha límite
            </Text>
            <Text size="md" fw="bolder" className="flex flex-row items-center">
              <HiOutlineClock className="mr-1 text-yellow-500" />{" "}
              {dayjs(item?.limit_date_billing)
                .locale("es")
                .format("DD/MMM/YYYY")
                .toUpperCase()}
            </Text>
          </Box>
        </SimpleGrid>
      </Card.Section>
      <Card.Section mx="sm">
        <SimpleGrid cols={2}>
          <Box className="flex flex-col gap-1">
            <Text size="xs" fw="bold" c="gray.6">
              Contacto
            </Text>
            <Text size="sm" truncate className="flex flex-row items-center  ">
              <MdOutlineMail className="mr-1 text-gray-400" />{" "}
              {item?.email ? item.email : "N/A"}
            </Text>
            <Text size="sm" className="flex flex-row items-center  ">
              {" "}
              <MdOutlinePhone className="mr-1 text-gray-400" />
              {item?.phone ? item.phone : "N/A"}
            </Text>
            <Text size="sm" className="flex flex-row items-center  ">
              {" "}
              <MdOutlinePhoneIphone className="mr-1 text-gray-400" />
              {item?.cellphone ? item.cellphone : "N/A"}
            </Text>
          </Box>
          <Box className="flex flex-col gap-1">
            <Text size="xs" fw="bold" c="gray.6">
              Plan
            </Text>
            <Text size="sm" className="flex flex-row items-center">
              <HiCreditCard className="mr-1 text-gray-400" />{" "}
              {item?.type_contract}
            </Text>
            <Text size="sm" className="flex flex-row items-center">
              <HiOutlineClipboard className="mr-1 text-gray-400" />{" "}
              {item?.name_type_plan}
            </Text>
            <Text size="sm" className="flex flex-row items-center">
              <HiOutlineClock className="mr-1 text-gray-400" /> Días límite:{" "}
              {item?.limit_payday}
            </Text>
          </Box>
        </SimpleGrid>
      </Card.Section>
      <Card.Section m="sm">
        <SimpleGrid cols={2}>
          <Box className="flex flex-row flex-wrap gap-2 items-end">
            <Text size="xs" fw="bold" c="gray.6">
              Cliente desde:
            </Text>
            <Text size="sm" fw="bold" c="gray.6">
              {dayjs(item?.start_from)
                .locale("es")
                .format("DD/MMM/YYYY")
                .toUpperCase()}
            </Text>
          </Box>
          <Box className="flex flex-row gap-2 items-center justify-end">
            <Badge
              color={
                dayjs(item?.limit_date_billing).isAfter(dayjs())
                  ? "green"
                  : "red"
              }
              size="md"
              autoContrast
            >
              {dayjs(item?.limit_date_billing).isAfter(dayjs())
                ? "Activo"
                : "Suspendido"}
            </Badge>
          </Box>
        </SimpleGrid>
      </Card.Section>
    </Card>
  );
};

export default CustomerCard;
