import React, { useEffect } from 'react'
import { Text, Title, Box, ActionIcon, Skeleton, Grid, Tooltip, Card, Button } from '@mantine/core'
import { DatePickerInput } from '@mantine/dates';
import { currencyFormat } from 'simple-currency-format';
import { HiSearch, HiOutlineCalendar } from 'react-icons/hi'

import Record from '../components/Records/Record';
import { useRecordStore } from '../store/useRecordStore';

const Records = () =>
{
  const {
    items,
    startDate,
    endDate,
    total,
    totalAmount,
    initialize,
    setStartDate,
    setEndDate,
    onSearch
  } = useRecordStore();

  useEffect(() =>
  {
    initialize();
  }, [ initialize ]);

  return (
    <Box className='w-full gap-1  overflow-x-auto py-2 px-2'>
      <div className='flex flex-col  justify-between'>
        <Box my="sm">
          <Title order={ 1 } >Registro de ingresos</Title>
          <Title order={ 5 } fw={ 400 } className='my-2'>Total de pagos registrados: { total }</Title>
        </Box>
        <Card withBorder radius="md">
          <Text size='sm' fw='bold'>Periodo</Text>
          <Box className='flex flex-col md:flex-row  gap-2 items-stretch md:items-center'>

            <Box className='flex flex-row items-center gap-1 w-48'>
              <Text size='sm' fw='bold'>De:</Text>
              <DatePickerInput rightSection={ <HiOutlineCalendar /> } w={ '100%' } variant='default' size="md" radius="md" value={ startDate } valueFormat="YYYY-MM-DD" onChange={ setStartDate } />
            </Box>
            <Box className='flex flex-row items-center gap-1 w-48'>
              <Text size='sm' fw='bold'>A:</Text>
              <DatePickerInput rightSection={ <HiOutlineCalendar /> } w={ '100%' } variant='default' size="md" radius="md" value={ endDate } valueFormat="YYYY-MM-DD" onChange={ setEndDate } />
            </Box>
            {/* <Tooltip label="Buscar">
              <ActionIcon w={ { base: '100%', md: 48 } } size="xl" color='yellow' variant='filled' radius='md' autoContrast onClick={ onSearch } ><HiSearch /></ActionIcon>

            </Tooltip> */}
            <Button
              leftSection={ <HiSearch /> }
              size="md" color='yellow' variant='filled' radius='md' autoContrast onClick={ onSearch }>
              Buscar
            </Button>
          </Box>
        </Card>



      </div>
      <Box className='flex flex-row justify-center md:justify-end md:items-end gap-2 my-2'>

        { items ? (<>
          <Title order={ 3 } >Total:</Title>
          <Title order={ 2 }>{ currencyFormat(totalAmount, 'es-MX', 'MXN', 2) }</Title>
          <Title order={ 3 } >MXN</Title>
        </>
        ) :
          <>
            <Skeleton height={ 48 } width={ 280 } radius="md" />
          </>
        }

      </Box>
      <Grid py="md" gap="md">
        { items ? items.map(item => (
          <Grid.Col key={ item._id }>
            <Record item={ item } />
          </Grid.Col>
        ))

          :
          <>
            <Skeleton height={ 80 } radius="md" my="xs" />
            <Skeleton height={ 80 } radius="md" my="xs" />
            <Skeleton height={ 80 } radius="md" my="xs" />
            <Skeleton height={ 80 } radius="md" my="xs" />
            <Skeleton height={ 80 } radius="md" my="xs" />
            <Skeleton height={ 80 } radius="md" my="xs" />
            <Skeleton height={ 80 } radius="md" my="xs" />

          </>
        }
      </Grid>
    </Box>
  )
}

export default Records
