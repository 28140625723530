import React from 'react'
import 'dayjs/locale/es'

import { Card, Table } from '@mantine/core';
import UsersCustomerRow from './UsersCustomerRow';

const UsersCustomersTable = ({ items, onOpen, openConfirm, openRecordModal, _openManualRenovation }) =>
{
  return (
    <Card shadow="sm" p="lg" radius="xl" withBorder mx="md" className='w-full'>
      <Table.ScrollContainer minWidth={ 500 }>
        <Table highlightOnHover>
          <Table.Thead>
            <Table.Tr className=' rounded-lg '>


              <Table.Th align="left">Nombre</Table.Th>
              <Table.Th align="center">Correo</Table.Th>
              <Table.Th># Cuenta</Table.Th>
              <Table.Th>Estatus</Table.Th>
              <Table.Th></Table.Th>
            </Table.Tr>
          </Table.Thead>
          <Table.Tbody>
            { items && items.map((item) => (
              <UsersCustomerRow item={ item } onOpen={ onOpen } openConfirm={ openConfirm } openRecordModal={ openRecordModal } _openManualRenovation={ _openManualRenovation } />
            )) }
          </Table.Tbody>
        </Table>
      </Table.ScrollContainer>
    </Card>
  )
}

export default UsersCustomersTable
