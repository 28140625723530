import { create } from 'zustand';
import { get_customers, get_customer_for_link } from '../utils/apis/customers';

export const useCustomer = create((set) => ({
  customers: [],
  user: null,
  getCustomersForLink: async () =>
  {
    const result = await get_customer_for_link();
    set({ customers: result });
  },

}));