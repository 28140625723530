import axios from 'axios';

export const postLogin = async (data) => {
  try {
    const url = `${process.env.REACT_APP_API_GCOM_URL}/auth-customer/login/admin`;
    console.log(url);
    console.log(data);

    const response = await axios.post(url, data, {
      headers: {
        'Content-Type': 'application/json',
      },
    });

    return response.data;
  } catch (error) {
    console.error('Login error:', error.response?.data || error.message);
    return null;
  }
}