import React from 'react'
import { Box } from '@mantine/core'
import CustomerCard from './CustomerCard'

const CustomerSection = ({ items, onOpen, openConfirm, openRecordModal, _openManualRenovation }) => {
  return (
    <Box className='grid grid-cols-1 md:grid-cols-2 xl:grid-cols-3 gap-4 '>
{
  items?.map((item, index) => {
    return (
      <CustomerCard
        key={ index }
        item={ item }
        onOpen={ onOpen }
        openConfirm={ openConfirm }
        openRecordModal={ openRecordModal }
        _openManualRenovation={ _openManualRenovation }
      />
    )
  })
}
    </Box>
  )
}

export default CustomerSection
