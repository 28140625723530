import { create } from 'zustand';
import dayjs from 'dayjs';
import { get_records, rollback_renovation_customer } from '../utils/apis/records';
import { get_customers } from '../utils/apis/customers';
import { get_renovation_nexttendays } from '../utils/apis/home';
import { getTempTotal } from '../utils/functions/getTotal';
import { calculateBankAmounts } from '../utils/functions/calculateBanksAmounts';
export const useDashboardStore = create((set) => ({
  itemsCustomers: null,
  itemsPostpago: null,
  itemsPrepago: null,
  totalPostpago: null,
  totalPrepago: null,
  totalAmount: null,
  records: null,
  renovation: null,
  banks: null,
  loadingBanks: true,
  rollbackData: null,
  openRollBack: false,

  fetchDashboardData: async () => {
    const startDate = dayjs().startOf('month').format('YYYY-MM-DD');
    const endDate = dayjs().endOf('month').add(1, 'day').format('YYYY-MM-DD');

    const recordsRes = await get_records({
      filter: [dayjs(startDate).format('DD/MM/YYYY'), dayjs(endDate).format('DD/MM/YYYY')],
      status: true
    });
    const postpago = recordsRes.records.filter((item) => item.type_contract === 'Postpago');
    const prepago = recordsRes.records.filter((item) => item.type_contract === 'Prepago');

    const customersRes = await get_customers();
    const renovationRes = await get_renovation_nexttendays();
    const today = dayjs().format('YYYY-MM-DD');


    set({
      itemsCustomers: customersRes.length,
      itemsPostpago: customersRes.filter((item) => item.type_contract === 'Postpago').length,
      itemsPrepago: customersRes.filter((item) => item.type_contract === 'Prepago').length,
      totalPostpago: getTempTotal(postpago),
      totalPrepago: getTempTotal(prepago),
      totalAmount: getTempTotal(recordsRes.records),
      records: recordsRes.records.reverse().slice(0, 9),
      renovation: renovationRes
        .filter(
          (item) => Date.parse(dayjs(item.limit_date_billing).format('YYYY-MM-DD')) >= Date.parse(today)
        )
        .sort((a, b) => new Date(a.limit_date_billing) - new Date(b.limit_date_billing))
        .slice(0, 9),
      banks: calculateBankAmounts(recordsRes.records),
      loadingBanks: false,
    });
  },

  toggleRollback: (item) => set({ openRollBack: true, rollbackData: item }),

  closeRollback: () => set({ openRollBack: false, rollbackData: null }),

  rollBackRenovation: async (item) => {
    await rollback_renovation_customer({ _id: item._id });
    set((state) => {
      state.fetchDashboardData();
      return { openRollBack: false, rollbackData: null };
    });
  }
}));
