import React from 'react';
import { Card, Text, Badge, Flex, Box, Progress } from '@mantine/core'
import dayjs from 'dayjs'
import 'dayjs/locale/es'
import relativeTime from 'dayjs/plugin/relativeTime'
dayjs.locale('es')
dayjs.extend(relativeTime)

const RowRenovation = ({ item, today }) => {
  const hoy = Date.parse(today);
  const limit_date_billing = Date.parse(dayjs(item?.limit_date_billing).format('YYYY-MM-DD'))

  const getRemainingDays = () => {
    const hoursDiff = dayjs(item?.limit_date_billing).diff(dayjs(), 'hour');
    if (hoy === limit_date_billing) return '0';
    if (hoursDiff >= 0 && hoursDiff <= 24) return '1';
    return dayjs(item?.limit_date_billing).diff(dayjs(), 'day').toString();
  };

  const getProgressValue = () => {
    const days = parseInt(getRemainingDays());
    if (days >= 10) return 0;
    if (days <= 0) return 100;
    return 100 - (days * 10); // Linear scale: 10 days = 0%, 0 days = 100%
  };

  return (
    <Card shadow="sm" padding="xl" radius="md" withBorder>
      <Card.Section py="xs" withBorder>
        <Flex gap='lg' p="xs" align='center' justify="space-between">
          <Box className='flex flex-col items-start justify-start w-48'>
            <Text size='md' fw="bold">{ dayjs(item?.limit_date_billing).locale('es').format('DD/MMM/YYYY').toUpperCase() }</Text>
            <Text size='sm' c="gray">{ item.num_account }</Text>
          </Box>
          <Box className='flex flex-row items-start justify-start'>
            { hoy === limit_date_billing && <Badge size="lg" color='orange' autoContrast>Hoy Vence</Badge> }
            { hoy < limit_date_billing && <Badge size="lg" color='yellow' autoContrast>Vencera pronto</Badge> }
          </Box>
        </Flex>
      </Card.Section>
      <Card.Section p="md" >
        <Box className='flex flex-col gap-2'>
          <Box className='flex flex-row justify-between items-center'>
            <Text size='md' fw="normal">Días restantes</Text>
            <Text size='md' fw="bold">{ getRemainingDays() }</Text>
          </Box>
          <Progress value={getProgressValue()} color={getProgressValue() <= 50 ? 'blue' : (getProgressValue() >= 51 && getProgressValue() <= 80) ? 'yellow' : 'red.7'} size="sm" radius="xl" />
        </Box>
      </Card.Section>
    </Card>
  )
}

export default RowRenovation
