import React, { useState } from 'react'

import { Table, Tooltip, Text, Box, ActionIcon, Flex, Menu, Badge } from '@mantine/core';
import { BiUnlink, BiLink, BiDotsVertical, BiUserX, BiUserCheck } from "react-icons/bi";
import { MdLink, MdLinkOff } from "react-icons/md";
import dayjs from 'dayjs'
import 'dayjs/locale/es'
import { useUserCustomer } from '../../store/useUserCustomer';

const UsersCustomerRow = ({ item, onOpen, openConfirm, openRecordModal, _openManualRenovation }) =>
{
  const [ show, setShow ] = useState(false)
  const { setOpenModal, setCloseModal, setUser, activate, desactivate } = useUserCustomer()

  const onOpenModal = () =>
  {
    setOpenModal(true)
    setUser(item)
  }

  const toggleStatus = (id) =>
  {
    if (item.isActive)
    {
      desactivate(id)
    } else
    {
      activate(id)
    }
  }


  return (
    <>
      <Table.Tr className=' py-4 px-5 '
        key={ item._id }
      >
        <Table.Td align="left">{ item?.full_name ? item.full_name : 'N/A' }</Table.Td>
        <Table.Td align="left">{ item?.email ? item.email : 'N/A' }</Table.Td>
        <Table.Td px="md" py="lg">
          { item?.id_customer?.num_account ? item?.id_customer?.num_account : 'N/A' }
        </Table.Td>
        <Table.Td align="left">{ item?.isActive ? <Badge color="indigo">Activo</Badge> : <Badge color="red">Inactivo</Badge> }</Table.Td>
        <Table.Td className='relative'>

          <Flex justify="flex-end" align="center" gap="xs">
            <Menu shadow="md" width={ 200 } >
              <Menu.Target>
                <ActionIcon aria-label='Ver acciones' variant='light' color="gray.6" size="lg"><BiDotsVertical /></ActionIcon>
              </Menu.Target>

              <Menu.Dropdown>
                <Menu.Item onClick={ () => onOpenModal() } leftSection={ item?.id_customer?.num_account ? <BiUnlink /> : <BiLink /> } aria-label=     { item?.id_customer?.num_account ? 'Desvincular' : 'Vincular' }>
                  { item?.id_customer?.num_account ? 'Desvincular' : 'Vincular' }
                </Menu.Item>

                <Menu.Item onClick={ () => toggleStatus(item._id) } leftSection={ item?.isActive ? <BiUserX /> : <BiUserCheck /> } aria-label=          { item?.isActive ? 'Suspender' : 'Activar' }>
                { item?.isActive ? 'Suspender' : 'Activar' }
                </Menu.Item>


              </Menu.Dropdown>
            </Menu>

          </Flex>


        </Table.Td>
      </Table.Tr >
    </>
  )
}

export default UsersCustomerRow
