import
  {
    HashRouter as Router,
    Routes,
    Route,
  } from "react-router-dom";
import Login from "../pages/Auth/Login";
import Home from "../pages/Home";
import VencimientoTreinta from '../pages/VencimientoTreinta';
import Plans from "../pages/Plans";
import Users from "../pages/Users";
import Customers from "../pages/Customers";
import NotFound from "../pages/NotFound/NotFound";
import Records from "../pages/Records";
import Dashboard from "../pages/Dashboard";
import PrivateRoute from "./PrivateRoutes";
import UsersCustomers from "../pages/UsersCustomers";

const AppRoute = () =>
{
  return (

      <Router>
        <Routes>
          <Route exact path="/*" element={ <NotFound /> } />
          <Route exact path="/login" element={ <Login /> } />
          <Route exact path="/" element={ <PrivateRoute><Dashboard /></PrivateRoute> } />
          <Route exact path="/dashboard" element={ <PrivateRoute><Dashboard /></PrivateRoute> } />
          <Route exact path="/renovation/ten-days" element={ <PrivateRoute><Home /></PrivateRoute> } />
          <Route exact path="/renovation/thirty-days" element={ <PrivateRoute><VencimientoTreinta /></PrivateRoute> } />
          <Route exact path="/plans" element={ <PrivateRoute><Plans /></PrivateRoute> } />
          <Route exact path="/customers" element={ <PrivateRoute><Customers /></PrivateRoute> } />
          <Route exact path="/users-customers" element={ <PrivateRoute><UsersCustomers /></PrivateRoute> } />
          <Route exact path="/users" element={ <PrivateRoute><Users /></PrivateRoute> } />
          <Route exact path="/reports" element={ <PrivateRoute><Records /></PrivateRoute> } />

        </Routes>
      </Router>
  );
}

export default AppRoute;
