import { create } from 'zustand';
import dayjs from 'dayjs';
import { get_records } from '../utils/apis/records';
import { getTempTotal } from '../utils/functions/getTotal';
import { calculateBankAmounts } from '../utils/functions/calculateBanksAmounts';
export const useRecordStore = create((set, get) => ({
  records: [],
  items: null,
  startDate: null,
  endDate: null,
  banks: null,
  total: 0,
  totalAmount: 0,

  // Initialize the store with default values
  initialize: () => {
    const startDate = dayjs().startOf('month');
    const endDate = dayjs().endOf('month');
    set({ startDate, endDate });
    get().getRecords(startDate, endDate);
  },

  // Set start date
  setStartDate: (date) => set({ startDate: date }),

  // Set end date
  setEndDate: (date) => set({ endDate: date }),

  // Get records based on date range
  getRecords: async (startDate, endDate) => {
    const data = {
      filter: [dayjs(startDate).format('DD/MM/YYYY'), dayjs(endDate).add(1, 'day').format('DD/MM/YYYY')],
      status: true
    };
    const res = await get_records(data);
    console.log(calculateBankAmounts(res.records));
    set({
      items: res.records.reverse(),
      total: res.total_renovacion,
      totalAmount: getTempTotal(res.records),
      banks: calculateBankAmounts(res.records),
      records: res.records
    });
  },

  // Search records with current date range
  onSearch: async () => {
    const { startDate, endDate } = get();
    await get().getRecords(startDate, endDate);
  },

  // Add a new record
  addRecord: (record) => set((state) => ({ records: [...state.records, record] })),

  // Remove a record by ID
  removeRecord: (recordId) => set((state) => ({ records: state.records.filter((record) => record.id !== recordId) })),
}));
