import React, { useEffect, useState, useContext } from 'react'
import
{
  Card,
  Title, Box, ActionIcon, Skeleton, Grid,
  Button, Flex, Input
} from '@mantine/core'

import CustomersTable from '../components/Customers/CustomersTable'
import CustomerContext from '../contexts/Customers/customerContext'
import ModalCustomers from '../components/Customers/Modal'
import { HiOutlineSearch } from 'react-icons/hi'
import { get_records } from '../utils/apis/records'
import ModalViewRecords from '../components/Customers/ModalViewRecords'
import ModalConfirm from '../components/Customers/ModalConfirm'
import ModalRenovation from '../components/Home/Modal'
import CustomerSection from '../components/Customers/CustomerSection'

const Customers = () =>
{
  const customerContext = useContext(CustomerContext);
  const { list, loading, getCustomersList, deleteCustomer } = customerContext;
  const [localLoading, setLocalLoading] = useState(true)
  const [ searchText, setSearchText ] = useState('')
  const [ items, setItems ] = useState(null);
  const [ item, setItem ] = useState(null)
  const [ isEditable, setIsEditable ] = useState(false)
  const [ isOpen, setIsOpen ] = useState(false);
  const [ isOpenConfirm, setIsOpenConfirm ] = useState(false);
  const [ itemConfirm, setItemConfirm ] = useState(null);
  const [ selectedAccount, setSelectedAccount ] = useState(null);
  const [ records, setRecords ] = useState(null);
  const [ openRecord, setOpenRecord ] = useState(false);
  const [ openManualRenovation, setOpenManualRenovation ] = useState(false)

  useEffect(() =>
  {
    (async () =>
    {
      await getCustomersList();
      setLocalLoading(false)
    })()
  }, [])

  useEffect(() =>
  {
    (async () =>
    {
      await getCustomersList();
      setLocalLoading(false)
    })()
  }, [ localLoading ]);

  const openConfirm = (item) =>
  {
    setItemConfirm(item);
    setIsOpenConfirm(true);
  }
  const closeConfirm = () =>
  {
    setIsOpenConfirm(false);
    setItemConfirm(null);
  }
  const removeCustomer = async () =>
  {
    await deleteCustomer({ _id: itemConfirm._id });
    closeConfirm();
    await getCustomersList();

  }
  const fetchRecords = async (num_account) =>
  {

    const recordsRes = await get_records({
      num_account: num_account
    });
    setRecords(recordsRes.records.sort((a, b) => new Date(b.created_date) - new Date(a.created_date)));
  }

  const openRecordModal = (num_account) =>
  {
    fetchRecords(num_account);
    setSelectedAccount(num_account);
    setOpenRecord(true);
  }

  const closeRecordModal = () =>
  {
    setOpenRecord(false);
    setSelectedAccount(null);
    setRecords(null);
  }


  const _openManualRenovation = (item) =>
  {
    setItem(item);
    setOpenManualRenovation(true);
  }

  const _closeManualRenovation = async() =>
  {
    setOpenManualRenovation(false);
    setItem(null);
    setLocalLoading(true)
  }
  useEffect(() =>
  {
    (async () =>
    {
      setItems(list)
    })()


  }, [ list ])

  const onOpen = (item, edit) =>
  {
    setIsOpen(true);
    setItem(item);
    setIsEditable(edit)
  }
  const onClose = () =>
  {
    setIsOpen(false);
    setItem(null);
  }

  const searchBd = (texto) =>
  {
    setSearchText(texto);
    const originalList = list

    const filters = searchText.split(' ')
    let resultFilter = originalList
    for (let fl of filters) {
      resultFilter = resultFilter.filter(item =>
      {
        const itemData = `${ item.full_name.toUpperCase() }  ${ item.email.toUpperCase() }  ${ item.num_account.toUpperCase() }`;
        const textData = fl.toUpperCase();

        return itemData.indexOf(textData) > -1;
      });

    }
    setItems(resultFilter)
  }

  return (
    <>
      <Box className='w-full gap-1 pt-2 px-2'>

        <Flex direction={ { base: 'column', lg: "row" } } justify="space-between" pb="lg" >
          <Title order={ 1 } my="sm">Clientes </Title>
          <Flex direction={ { base: 'column', lg: "row" } } justify="flex-end" align="center" gap="md">
            <Flex align="center" gap="xs" w={ { base: '100%', lg: 300 } }>
              <Input size="lg" w={ { base: '100%', lg: 300 } } variant='filled' placeholder='Buscar cliente' value={ searchText } onChange={ (e) => searchBd(e.target.value) } />
              <ActionIcon size="xl" color='yellow' variant='light' radius='md' autoContrast onClick={ () => searchBd(searchText) } ><HiOutlineSearch /></ActionIcon>
            </Flex>
            <Button size='md' fullWidth radius='md' color='yellow' autoContrast onClick={ () => onOpen(null, true) }>Nuevo</Button>
          </Flex>


        </Flex>
        <Flex justify="space-between" w="100%">
          { !localLoading ?
            <CustomerSection items={ items } onOpen={ onOpen } openConfirm={ openConfirm } openRecordModal={ openRecordModal } _openManualRenovation={_openManualRenovation}/>
            :
            <Card shadow="sm" p="xl" radius="xl" withBorder mx="md" w="100%">
              <Grid w="100%">
                <Skeleton height={ 80 } radius="md" my="xs" />
                <Skeleton height={ 80 } radius="md" my="xs" />
                <Skeleton height={ 80 } radius="md" my="xs" />
                <Skeleton height={ 80 } radius="md" my="xs" />
                <Skeleton height={ 80 } radius="md" my="xs" />
                <Skeleton height={ 80 } radius="md" my="xs" />
                <Skeleton height={ 80 } radius="md" my="xs" />

              </Grid>
            </Card>
          }
        </Flex>
      </Box>
      <ModalCustomers isOpen={ isOpen } onOpen={ onOpen } onClose={ onClose } item={ item } edit={ isEditable } />
      <ModalViewRecords isOpen={ openRecord } onOpen={ openRecordModal } onClose={ closeRecordModal } num_account={ selectedAccount } records={ records } />
      <ModalConfirm isOpen={ isOpenConfirm } onOpen={ openConfirm } onClose={ closeConfirm } item={ itemConfirm } remove={ removeCustomer } />
      <ModalRenovation   isOpen={ openManualRenovation } onOpen={ _openManualRenovation } onClose={ _closeManualRenovation } item={ item } />

    </>
  )
}

export default Customers
