/* eslint-disable react-hooks/rules-of-hooks */
import React, { useContext } from "react";
import { Navigate, useLocation } from "react-router-dom";
import Sidebar from "../components/Sidebar/Sidebar";
import Header from "../components/Header";
import AuthContext from "../contexts/Auth/authContext";
import { AppShell, ScrollArea, NavLink, Box, Title, Text, useMantineColorScheme } from "@mantine/core";
import { useDisclosure } from "@mantine/hooks";
import { HiOutlineLogout, HiSun, HiMoon } from "react-icons/hi";
import ToogleButton from "../components/ToggleButton";
import useIsMobileOrTablet from "../hooks/useIsMobileorTablet";
import Banks from "../components/Dashboard/Banks";
import ReportBanks from "../components/Records/ReportBanks";

const PrivateRoute = ({ children }) =>
{
  const { setColorScheme, colorScheme } = useMantineColorScheme();
  const { token, logout } = useContext(AuthContext);
  const [ opened, { toggle } ] = useDisclosure();
  const isMobileOrTablet = useIsMobileOrTablet();
  const location = useLocation();
  if (!token) {
    return <Navigate to="/login" replace />;
  }
  const visible = location.pathname === "/dashboard" || location.pathname === "/reports" || location.pathname === "/";
  return (
    <AppShell

      header={ { height: 60, collapsed: !isMobileOrTablet } }
      navbar={ {
        width: 280,
        breakpoint: "md",
        collapsed: { mobile: !opened },
      } }
      footer={ { height: 60 } }
      aside={ { width: visible ? 352 : 0, breakpoint: 'md', collapsed: { desktop: false, mobile: true } } }
      padding="md"
    >
      <AppShell.Header>
        <Header opened={ opened } toggleSidebar={ toggle } />
      </AppShell.Header>

      <AppShell.Navbar>
        <AppShell.Section grow my="md" component={ ScrollArea }>
          <Sidebar toggleSidebar={ toggle } />
        </AppShell.Section>

        <AppShell.Section>
          <Box className="py-2 px-2 w-full">
            <NavLink
              onClick={ () => setColorScheme(colorScheme === "dark" ? "light" : "dark") }
              label="Cambiar tema"
              leftSection={ colorScheme === "dark" ? <HiSun className="text-md lg:text-lg" /> : <HiMoon className="text-md lg:text-lg" /> }
            >
            </NavLink>
            <NavLink
              onClick={ logout }
              label="Cerrar sesión"
              leftSection={ <HiOutlineLogout className="text-md lg:text-lg" /> }
            />
          </Box>
        </AppShell.Section>
      </AppShell.Navbar>

      <AppShell.Main>{ children }</AppShell.Main>
      {
        (location.pathname === "/dashboard" || location.pathname === "/") &&
        <AppShell.Aside p="md" className="overflow-auto">
          <div className="flex flex-col gap-2">
            <Title order={ 4 }>Bancos</Title>
            <Banks />
          </div>

        </AppShell.Aside> }
      {
        (location.pathname === "/reports") &&
        <AppShell.Aside p="md" className="overflow-auto">
          <div className="flex flex-col gap-2">
            <Title order={ 4 }>Reporte de bancos</Title>
            <ReportBanks />
          </div>
        </AppShell.Aside> }

      <AppShell.Footer p="md">
        <Box className="flex flex-row items-center justify-center">
          <Text size="sm" c="dimmed">
            © 2025 - Todos los derechos reservados
          </Text>
        </Box>
      </AppShell.Footer>
    </AppShell>
  );
};

export default PrivateRoute;
