import React from 'react'
import { useMantineColorScheme, Box, Button } from '@mantine/core'
import { TbSun, TbMoon } from "react-icons/tb";


const ToggleButton = ({ showText }) =>
{
  const { setColorScheme, colorScheme } = useMantineColorScheme();
  return (
    <Box my="lg" >
      { colorScheme === "dark" ? (
        <Button size='md'  onClick={ () => setColorScheme("light") } leftSection={ <TbSun size={ 24 } /> } variant="subtle" color='light' >

          Light mode
        </Button>
      ) : (
        <Button size='md'  onClick={ () => setColorScheme("dark") } leftSection={ <TbMoon size={ 24 } /> } variant="subtle" color='dark' >

          Dark mode
        </Button>
      ) }
    </Box>

  )
}

export default ToggleButton

