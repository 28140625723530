export const get_user_customers = async (data) => {
  try {
    const url = `${process.env.REACT_APP_API_GCOM_URL}/user-customers/`;
    const params = {
      method: 'GET',
      headers: {
        "Access-Control-Allow-Origin": "*",
        "Content-Type": "application/json",
        'Accept': 'application/json',
      },
      body: JSON.stringify(data)
    };

    const response = await fetch(url, params);
    const result = await response.json()
    return result

  } catch (error) {
    console.log(error)
    return null
  }
}

export const post_link_customer = async (id,data) => {
  const token = await localStorage.getItem('token')
  try {
    const url = `${process.env.REACT_APP_API_GCOM_URL}/user-customers/link/${id}`;
    const params = {
      method: 'PATCH',
      headers: {
        "Access-Control-Allow-Origin": "*",
        "Content-Type": "application/json",
        'Accept': 'application/json',
        "Authorization": `Bearer ${token}`
      },
      body: JSON.stringify(data)
    };

    const response = await fetch(url, params);
    const result = await response.json()
    return result

  } catch (error) {
    console.log(error)
    return null
  }
}
export const post_unlink_customer = async (id,data) => {
  const token = await localStorage.getItem('token')
  try {
    const url = `${process.env.REACT_APP_API_GCOM_URL}/user-customers/unlink/${id}`;
    const params = {
      method: 'PATCH',
      headers: {
        "Access-Control-Allow-Origin": "*",
        "Content-Type": "application/json",
        'Accept': 'application/json',
        "Authorization": `Bearer ${token}`
      },
      body: JSON.stringify(data)
    };

    const response = await fetch(url, params);
    const result = await response.json()
    return result

  } catch (error) {
    console.log(error)
    return null
  }
}

export const post_activate_customer = async (id) => {
  const token = await localStorage.getItem('token')
  try {
    const url = `${process.env.REACT_APP_API_GCOM_URL}/user-customers/activate/${id}`;
    const params = {
      method: 'PATCH',
      headers: {
        "Access-Control-Allow-Origin": "*",
        "Content-Type": "application/json",
        'Accept': 'application/json',
        "Authorization": `Bearer ${token}`
      },
    };

    const response = await fetch(url, params);
    const result = await response.json()
    return result

  } catch (error) {
    console.log(error)
    return null
  }
}

export const post_desactivate_customer = async (id) => {
  const token = await localStorage.getItem('token')
  try {
    const url = `${process.env.REACT_APP_API_GCOM_URL}/user-customers/suspend/${id}`;
    const params = {
      method: 'PATCH',
      headers: {
        "Access-Control-Allow-Origin": "*",
        "Content-Type": "application/json",
        'Accept': 'application/json',
        "Authorization": `Bearer ${token}`
      },
    };

    const response = await fetch(url, params);
    const result = await response.json()
    return result

  } catch (error) {
    console.log(error)
    return null
  }
}