import { create } from 'zustand';
import { get_user_customers, post_link_customer, post_unlink_customer, post_activate_customer, post_desactivate_customer } from '../utils/apis/customerAccount';

export const useUserCustomer = create((set) => ({
  users: [],
  loading: true,
  user: null,
  getUsers: async () =>{
    const result = await get_user_customers();
    set({ users: result, loading: false });
  },
  setUser: (user) => set({ user }),
  openModal: false,
  setOpenModal: (openModal) => set({ openModal }),
  setCloseModal: (openModal) => set({ openModal, user: null }),
  postLink: async(id, data) =>{
    await post_link_customer(id, data);
    set({ openModal: false, user: null, loading: true });
  },
  postUnLink: async(id, data) =>{
    await post_unlink_customer(id, data);
    set({ openModal: false, user: null, loading: true });
  },
  activate: async(id, data) =>{
    await post_activate_customer(id, data);
    set({ openModal: false, user: null, loading: true });
  },
  desactivate: async(id) =>{
    await post_desactivate_customer(id);
    set({ openModal: false, user: null, loading: true });
  },

}));