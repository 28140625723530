import React from 'react'
import { Box, Skeleton } from '@mantine/core';
import { currencyFormat } from 'simple-currency-format';
import BoxBank from '../Dashboard/BoxBanks';
import { useRecordStore } from '../../store/useRecordStore';

const ReportBanks = () =>
{
  const { banks } = useRecordStore();
  const formatCurrency = (amount) => `${ currencyFormat(amount, 'es-MX', 'MXN', 2) } mxn`;
  const renderBanks = (banks) =>
  {
    if (!Array.isArray(banks) || banks.length === 0) {
      return <Skeleton height={ 80 } radius="md" my={ 4 } />;
    }

    // Ordenar bancos alfabéticamente para una presentación consistente
    const sortedBanks = banks.sort((a, b) => a.bank.localeCompare(b.bank));

    return sortedBanks.map((item, index) => (
      <BoxBank
        key={ index }
        title={ item.bank === "undefined" ? "Sin identificar" : item.bank }
        value={ formatCurrency(item.amount) }
        color="gray"
      />
    ));
  };
  return (
    <>
      {renderBanks(banks)}
    </>
  )
}

export default ReportBanks
