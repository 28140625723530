import React, { useState, useEffect, useMemo } from 'react';
import { currencyFormat } from 'simple-currency-format';
import { Box, Divider, Flex, Title, Card, Skeleton, SegmentedControl } from '@mantine/core';
import dayjs from 'dayjs';
import 'dayjs/locale/es-mx';
import { useLocation } from 'react-router-dom';
import { PiUserCircle, PiUsers, PiCurrencyDollar, PiWallet } from 'react-icons/pi';

import BoxSummary from '../components/Dashboard/BoxSummary';
import Record from '../components/Dashboard/Record';
import RowRenovation from '../components/Dashboard/RowRenovation';

import { useDashboardStore } from '../store/useDashboardStore';
import ConfirmRollback from '../components/Dashboard/ConfirmRollback';
import Banks from '../components/Dashboard/Banks';

const Dashboard = () =>
{
  const today = useMemo(() => dayjs().format('YYYY-MM-DD'), []);
  const tenDays = useMemo(() => dayjs().add(10, 'day').format('YYYY-MM-DD'), []);
  const location = useLocation();
  const [ value, setValue ] = useState('lastRenovation');


  const {
    itemsCustomers,
    itemsPostpago,
    itemsPrepago,
    totalPostpago,
    totalPrepago,
    totalAmount,
    records,
    renovation,
    loadingBanks,
    rollbackData,
    openRollBack,
    fetchDashboardData,
    toggleRollback,
    closeRollback,
    rollBackRenovation
  } = useDashboardStore();

  useEffect(() =>
  {
    // Fetch dashboard data whenever the component mounts or when the location changes
    // This ensures data is refreshed every time the user visits this page
    if (location.pathname === '/dashboard') {
      fetchDashboardData();
    }
  }, [ location, fetchDashboardData ]);

  return (
    <Box className="w-full gap-4 px-2 h-[100%]">
      <Box className='flex flex-row'>
        <Box className='flex-grow'>
          <Flex justify="flex-start" align="end" gap="xs">
            <Title order={ 1 }>Dashboard</Title>
            <Title order={ 4 }> - { dayjs().locale('es-mx').format('MMMM') }</Title>
          </Flex>
          <Box className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-2 gap-2 mb-4">
            { renderBoxSummaries({
              itemsCustomers,
              itemsPostpago,
              itemsPrepago,
              totalPostpago,
              totalPrepago,
              totalAmount,
            }) }
          </Box>
          <Divider py="md" />
          <Box className='block lg:hidden'>
            <Section title="Bancos">
              { loadingBanks ? renderSkeletons(6) : <Banks /> }
            </Section>
          </Box>
          <SegmentedControl
            fullWidth size="md" radius="md"
            my="md"
            value={ value }
            onChange={ setValue }
            data={ [
              { label: 'Últimas renovaciones', value: 'lastRenovation' },
              { label: 'Próximos a vencer', value: 'next' },

            ] }
          />
          <Box className="my-4 grid grid-cols-1">
            { value === 'lastRenovation' && <CardSection title="Últimas renovaciones" data={ records }>
              { (item) => <Record key={ item._id } item={ item } rollBackRenovation={ toggleRollback } /> }
            </CardSection> }
            { value === 'next' && <CardSection title="Próximos a vencer" data={ renovation }>
              { (item) => <RowRenovation key={ item._id } item={ item } today={ today } tenDays={ tenDays } /> }
            </CardSection> }
          </Box>
        </Box>

      </Box>
      <ConfirmRollback show={ openRollBack } onHide={ closeRollback } item={ rollbackData } onConfirm={ rollBackRenovation } />
    </Box>
  );
};

const renderBoxSummaries = ({ itemsCustomers,
  itemsPostpago,
  itemsPrepago,
  totalPostpago,
  totalPrepago,
  totalAmount, }) =>
{
  const summaries = [
    { title: 'Usuario postpago', value: itemsPostpago, icon:<PiUserCircle size="18"/> },
    { title: 'Usuarios prepago', value: itemsPrepago, icon:<PiUserCircle size="18"/> },
    { title: 'Total usuarios', value: itemsCustomers, icon:<PiUsers size="18"/>   },
    { title: 'Total Postpago', value: totalPostpago, icon:<PiCurrencyDollar size="18"/>, currency: true },
    { title: 'Total Prepago', value: totalPrepago, icon:<PiCurrencyDollar size="18"/>, currency: true },
    { title: 'Total de ingresos', value: totalAmount, icon:<PiWallet size="18"/>, currency: true },
  ];
  return summaries.map((item, index) =>
    item.value !== undefined && item.value !== null ? (
      <BoxSummary
        key={ index }
        title={ item.title }
        icon={item.icon}
        value={ item.currency ? formatCurrency(item.value) : item.value }
        color="gray"
      />
    ) : (
      <Skeleton key={ index } height={ 80 } radius="md" />
    )
  );
};

const renderSkeletons = (count) => Array.from({ length: count }, (_, i) => <Skeleton key={ i } height={ 80 } radius="md" my={ 4 } />);
const formatCurrency = (amount) => `${ currencyFormat(amount, 'es-MX', 'MXN', 2) } mxn`;
const Section = ({ title, children }) => (
  <Box>
    <Title order={ 4 }>{ title }</Title>
    <section className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-2">{ children }</section>
  </Box>
);

// const SectionBanks = ({ title, children }) => (
//   <Box className='w-full lg:w-64 mx-2 gap-2 hidden lg:block'>
//     <Title order={ 4 }>{ title }</Title>
//     <section className="grid grid-cols-1 gap-2">{ children }</section>
//   </Box>
// );

const CardSection = ({ title, data, children }) => (
  <Box shadow="sm" padding="xl" radius="lg" withBorder>

    { data
      ? data.map((item) => (
        <Box key={ item._id } py="xs" className='gap-2'>
          { children(item) }
        </Box>
      ))
      : renderSkeletons(6) }
  </Box>
);

export default Dashboard;
