export const calculateBankAmounts = (addresses) => {
  const bankAmountMap = addresses.reduce((acc, { type_bank_name, amount }) => {
    const bankName = type_bank_name ? type_bank_name.trim().toLowerCase() : "undefined";
    acc[bankName] = (acc[bankName] || 0) + parseFloat(amount || 0);
    return acc;
  }, {});

  const predefinedBanks = [
    "banregio",
    "azteca",
    "bbva",
    "hsbc",
    "mercado pago",
    "santander",
    "efectivo",
    "spin",
  ];

  predefinedBanks.forEach((bank) => {
    if (!(bank in bankAmountMap)) {
      bankAmountMap[bank] = 0;
    }
  });

  return Object.entries(bankAmountMap).map(([bank, amount]) => ({ bank, amount }));
};
