import React from 'react'
import { Image, Text, Box, Card } from '@mantine/core'
import Loading from '../Loading/Loading'
import Banregio from "../../assets/banks/banregio.webp";
import Azteca from "../../assets/banks/azteca.webp";
import Bbva from "../../assets/banks/bbva2.png";
import Hsbc from "../../assets/banks/hsbc.png";
import MercadoPago from "../../assets/banks/mercado-pago.webp";
import Santander from "../../assets/banks/santander-logo-1.webp";
import Efectivo from "../../assets/banks/efectivo.png";
import Spin from "../../assets/banks/spin.png";

const BoxBank = ({ title, value }) =>
{
  const bankImages = {
    "mercado pago": MercadoPago,
    bbva: Bbva,
    santander: Santander,
    hsbc: Hsbc,
    banregio: Banregio,
    azteca: Azteca,
    efectivo: Efectivo,
    spin: Spin,
  };

  const bankColor = {
    "mercado pago": 'bg-blue-100',
    bbva: 'bg-indigo-100',
    santander: 'bg-red-100',
    hsbc: 'bg-red-50',
    banregio: 'bg-orange-50',
    azteca: 'bg-green-50',
    efectivo: 'bg-green-50',
    spin: 'bg-purple-50',
  };

  const renderBankImage = (bankName) => (

    <Box className='w-8 h-8 flex flex-col items-center justify-center rounded-full overflow-auto'>
      <Image
        src={ bankImages[ bankName ] }
        alt={ bankName }
        title={ bankName }
        className="object-contain"
      />
    </Box>

  );

  return (
    <Card shadow="sm" padding="none" radius="md" className='border rounded-lg w-full'>
      <Card.Section className={`p-3 ${bankColor[title]}`} withBorder >
        <Box className='flex flex-row gap-2 items-center justify-start'>
          { renderBankImage(title) }
          <Text fw="bold" c="dark" className={`uppercase overflow-hidden text-ellipsis whitespace-nowrap font-bold`}>{ title }</Text>
        </Box>
      </Card.Section>
      <Box className='flex flex-row gap-2 p-3 justify-between'>
      <Text size="sm" fw="bold" className="overflow-hidden text-ellipsis whitespace-nowrap self-center">Saldo</Text>
        { value ?
          <Text size="xl" fw="bolder" className="overflow-hidden text-ellipsis whitespace-nowrap self-end">{ value }</Text>
          :
          <Loading /> }
      </Box>
    </Card>
  )
}

export default BoxBank
