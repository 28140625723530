import React from 'react'
import { Card, Text, Title, Box } from '@mantine/core'
import Loading from '../Loading/Loading'

const BoxSummary = ({ title, value, icon }) =>
{
  return (
    <Card shadow="sm" padding="none" radius="md">
      <Card.Section className='p-4' >
        <Box className='flex flex-row justify-between items-center'>
          <Text fw="bold" c="dimmed" size="sm">{ title }</Text>
          { icon }
        </Box>
      </Card.Section>
      <Box className='p-2'>
        { value ?
          <Title order={ 3 } className='text-right mx-2'>{ value }</Title>
          :
          <Loading /> }
      </Box>
    </Card>
  )
}

export default BoxSummary
